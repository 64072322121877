import { Skeleton } from '@beeline/design-system-react/extracted-styles';

import { useMobileView } from ':hooks/useMobileView';

import styles from './styles.module.scss';

const animated = true;

/** @description Скелетон блока "как начать" */
export const HowStartSkeleton = () => {
  const { isNormalViewUp } = useMobileView();

  return (
    <div className={styles.howStart}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Skeleton animated={animated} height={32} radius={12} />
          <Skeleton animated={animated} height={18} radius={12} />
          <Skeleton animated={animated} height={18} radius={12} />
          <Skeleton animated={animated} height={18} radius={12} />
          <Skeleton animated={animated} height={18} radius={12} />
        </div>
        {isNormalViewUp && <Skeleton height={44} radius={12} width={214} />}
        <div className={styles.cards}>
          <Skeleton animated={animated} height={218} radius={12} />
          <Skeleton animated={animated} height={218} radius={12} />
          <Skeleton animated={animated} height={218} radius={12} />
        </div>
      </div>
    </div>
  );
};
