import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

interface Vacancy {
  icon: Icons;
  subtitleList: string[][];
  title: string;
}

const adaptiveItCards: Vacancy[] = [
  {
    icon: Icons.Code,
    title: 'ИТ-решения и\xa0продукты',
    subtitleList: [['Разработка', 'Аналитика'], ['Управление продуктами'], ['Дизайн', 'BigData', 'QA']],
  },
  {
    icon: Icons.Code,
    title: 'ИТ-решения и\xa0продукты',
    subtitleList: [
      ['Разработка', 'Аналитика', 'Управление продуктами'],
      ['Дизайн', 'BigData', 'QA'],
    ],
  },
];

const adaptiveTvCards: Vacancy[] = [
  {
    icon: Icons.Wifi,
    title: 'Продажи тв и\xa0домашнего интернета',
    subtitleList: [['Без опыта', 'Подработка'], ['Агенты', 'Промоутеры'], ['Активные продажи']],
  },
  {
    icon: Icons.Wifi,
    title: 'Продажи тв и\xa0домашнего интернета',
    subtitleList: [
      ['Без опыта', 'Подработка', 'Агенты'],
      ['Промоутеры', 'Активные продажи'],
    ],
  },
];

const adaptiveBusinessCards: Vacancy[] = [
  {
    icon: Icons.NetworkAlt,
    title: 'Развитие бизнеса и\xa0продаж',
    subtitleList: [
      ['Продажи В2В', 'Реклама'],
      ['Маркетинг', 'Продажи В2C'],
      ['Высший менеджмент', 'PR'],
    ],
  },
  {
    icon: Icons.NetworkAlt,
    title: 'Развитие бизнеса и\xa0продаж',
    subtitleList: [
      ['Продажи В2В', 'Реклама', 'Маркетинг'],
      ['Продажи В2C', 'Высший менеджмент', 'PR'],
    ],
  },
];

const adaptiveOfficeCards: Vacancy[] = [
  {
    icon: Icons.StatUp,
    title: 'Офисы продаж и обслуживания',
    subtitleList: [['Розничные продажи'], ['Офисы продаж'], ['Без опыта работы']],
  },
  {
    icon: Icons.StatUp,
    title: 'Офисы продаж и обслуживания',
    subtitleList: [['Розничные продажи', 'Офисы продаж'], ['Без опыта работы']],
  },
];

const adaptiveCentersCards: Vacancy[] = [
  {
    icon: Icons.HeadsetHelp,
    title: 'Контактные центры',
    subtitleList: [['Поддержка клиентов', 'Телепродажи'], ['Техническая поддержка'], ['Менеджмент'], ['Без опыта']],
  },
  {
    icon: Icons.HeadsetHelp,
    title: 'Контактные центры',
    subtitleList: [['Поддержка клиентов', 'Телепродажи'], ['Техническая поддержка', 'Менеджмент'], ['Без опыта']],
  },
];

const vacanciesCards: Vacancy[] = [
  {
    icon: Icons.Antenna,
    title: 'Инженерия объектов связи',
    subtitleList: [['Аренда позиций', 'Энергетика'], ['Строительство и эксплуатация сети'], ['Монтаж оборудования']],
  },
];

export const vacanciesCardValues = (isNormal: boolean, isLarge: boolean) => {
  const result = [...vacanciesCards];

  result.splice(0, 0, adaptiveItCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(2, 0, adaptiveTvCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(3, 0, adaptiveBusinessCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(4, 0, adaptiveOfficeCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(5, 0, adaptiveCentersCards[isNormal ? 1 : 0]);

  return result;
};
