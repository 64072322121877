import { WorkIsSkeleton } from '../HomeSkeleton/WorkIsSkeleton';

import styles from './styles.module.scss';
import { MainInfoSkeleton } from './MainInfoSkeleton';
import { DescriptionInfoSkeleton } from './DescriptionInfoSkeleton';
import { HowStartSkeleton } from './HowStartSkeleton';

import { ReturnButton } from ':pages/Vacancy/ReturnButton';

/** @description Скелетон страницы вакансии */
export const VacancySkeleton = () => {
  return (
    <div className={styles.vacancy}>
      <MainInfoSkeleton />
      <DescriptionInfoSkeleton />
      <HowStartSkeleton />
      <WorkIsSkeleton />
      <ReturnButton />
    </div>
  );
};
