import { useMutation } from '@tanstack/react-query';

import { Endpoints } from ':constants/endpoints';

import { BMassResume, QueryCallback } from ':types';
import { API } from ':api';

/**
 * @description Отправка резюме по вакансии масс.подбора
 */
export const useMassResume = ({ onError }: QueryCallback = {}) => {
  return useMutation({
    mutationKey: ['massResume'],
    mutationFn: (data: BMassResume & { [index: string]: string }) => {
      const body = new FormData();

      Object.keys(data).forEach((key: string) => body.append(key, data[key]));

      return API.post({
        url: Endpoints.massResume(),
        body,
      });
    },
    onError,
  });
};
