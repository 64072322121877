import { FC } from 'react';
import { MaskField, TextField, Typography } from '@beeline/design-system-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMobileId } from ':hooks/useMobileId';
import { VacancyFormFooter, Header, VacancyFormHeader, Modal, MobileId } from '..';
import { useMassResume } from ':hooks/useMassResume';

import styles from './styles.module.scss';
import { schema } from './schema';

import { BVacancy } from ':types';

interface Props {
  /** Функция закрытия модального окна */
  onClose: (open: boolean) => void;
  /** Ошибка отправки */
  onError: () => void;
  /** Функция успешного отклика на вакансию */
  onSuccess: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
  /** Название вакансии */
  vacancyName: string;
}

/** Типы полей формы */
type MassVacancyFormType = z.infer<typeof schema>;

/**
 * @description Компонент отклика на вакансию массового подбора
 */
export const MassVacancyForm: FC<Props> = ({ vacancyName, onClose, onError, onSuccess, vacancy }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm<MassVacancyFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });
  const { isOpen, close, open, onChangeNumber, onRetry, onSuccess: onSuccessId } = useMobileId({ onSuccess });
  const handleClick = () => {
    onClose(false);
  };
  const { mutateAsync: massResumeApply } = useMassResume({ onError });

  const onSubmit = async (data: MassVacancyFormType) => {
    try {
      massResumeApply({
        ...data,
        external_system_vacancy_id: vacancy.external_system_vacancy_id,
        external_system_name: vacancy.external_system_name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      open();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header />

      <VacancyFormHeader onClick={handleClick} title={vacancyName} />

      <div className={styles.massVacancyForm}>
        <div className={styles.contacts}>
          <div className={styles.content}>
            <Typography variant="subtitle1">Контактные данные</Typography>
            <TextField
              error={!!errors.full_name?.message}
              fullWidth
              helperText={errors.full_name?.message}
              label="Фамилия и имя*"
              {...register('full_name', { required: true })}
            />
            <MaskField
              error={!!errors.phone?.message}
              fullWidth
              helperText={errors.phone?.message || 'Мы отправим смс для подтверждения отклика'}
              label="Номер телефона*"
              maskConfig={{
                mask: '+{7}(000)000-00-00',
              }}
              {...register('phone', { required: true })}
            />
          </div>
        </div>
      </div>

      <Modal className={styles.mobileIdModal} open={isOpen}>
        <MobileId
          number={getValues().phone}
          onChangeNumber={onChangeNumber}
          onClose={close}
          onRetry={onRetry}
          onSuccess={onSuccessId}
        />
      </Modal>

      <VacancyFormFooter isDisabled={!isValid} isSubmitting={isSubmitSuccessful} />
    </form>
  );
};
