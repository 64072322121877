import { FC, useRef, useState } from 'react';
import {
  Button,
  DropdownMenu,
  Icon,
  Snackbar,
  Tooltip,
  Typography,
} from '@beeline/design-system-react/extracted-styles';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { ReturnButton } from '../ReturnButton';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import telegramIcon from ':assets/svg/telegram.svg';
import VKIcon from ':assets/svg/vk.svg';
import OKIcon from ':assets/svg/ok.svg';
import { BVacancy } from ':types';

const shareList = [
  {
    icon: telegramIcon,
    text: 'Telegram',
    url: 'https://t.me/beeline',
  },
  {
    icon: VKIcon,
    text: 'ВКонтакте',
    url: 'https://vk.com/beeline',
  },
  {
    icon: OKIcon,
    text: 'ОК',
    url: 'https://ok.ru/beeline',
  },
];

interface Props {
  /** Событие клика кнопка */
  onClick?: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
}

/** @description Блок с основными данными о вакансии */
export const MainInfo: FC<Props> = ({ onClick: handleClick, vacancy }) => {
  const { displayButton, grossVariant, titleVariant } = useVariant();
  const ref = useRef<HTMLButtonElement | null>(null);
  const [shareOpen, setShareOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleShare = () => setShareOpen((prev) => !prev);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
  };

  const handleHideCopySnackbar = () => setSnackbarOpen(false);

  const additionalData = [
    {
      name: 'Город',
      value: vacancy.city.join(', '),
    },
    {
      name: 'Формат работы',
      value: vacancy.format,
    },
    {
      name: 'Опыт работы',
      value: vacancy.grade,
    },
    {
      name: 'Направление',
      value: vacancy.direction,
    },
  ];

  return (
    <div className={styles.mainInfo}>
      <div className={styles.content}>
        <ReturnButton />
        <Tooltip title="Поделиться">
          <Button ref={ref} className={styles.shareButton} onClick={handleShare} size="large">
            <Icon iconName={Icons.ShareAndroid} />
          </Button>
        </Tooltip>
        <Typography className={styles.title} variant={titleVariant}>
          {vacancy.name}
        </Typography>
        <Typography className={styles.gross} variant={grossVariant}>
          {vacancy.gross_salary}
        </Typography>
        <div className={styles.additionalInfo}>
          {additionalData.map(({ name, value }) => (
            <div key={name}>
              <Typography className={styles.name} variant="body3">
                {name}
              </Typography>
              <Typography variant="body2">{value}</Typography>
            </div>
          ))}
          {displayButton && (
            <Button onClick={handleClick} size="large" variant="contained">
              Откликнуться
            </Button>
          )}
        </div>
      </div>
      {shareOpen && (
        <DropdownMenu
          align="right"
          onOutsideClick={handleShare}
          positionerClassName={styles.sharePositioner}
          targetElement={ref}
        >
          <div className={styles.shareList}>
            <div className={styles.shareRow} onClick={handleCopyToClipboard} role="button" tabIndex={0}>
              <Icon iconName={Icons.Copy} size="large" />
              <Typography variant="body2">Скопировать ссылку</Typography>
            </div>
            {shareList.map(({ icon, text, url }) => (
              <a key={text} className={styles.shareRow} href={url}>
                <img alt={text} className={styles.icon} src={icon} />
                <Typography variant="body2">{text}</Typography>
              </a>
            ))}
          </div>
        </DropdownMenu>
      )}
      <Snackbar
        delay={4000}
        horizontal="center"
        message="Ссылка скопирована в буфер обмена"
        onClose={handleHideCopySnackbar}
        open={snackbarOpen}
      />
    </div>
  );
};
