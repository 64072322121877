import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import { useMobileView } from ':hooks/index';

import { VacancyCard } from './vacancyCard';
import styles from './styles.module.scss';
import { useVariant } from './hooks';
import { vacanciesCardValues } from './cardValues';

/**
 * @description Блок с вакансиями "Найди себя в билайне"
 */
export const OpenVavanciesBlock = () => {
  const navigate = useNavigate();
  const { isLargeViewUp, isNormalViewUp } = useMobileView();
  const { buttonVariant, endButton, startButton, titleVariant } = useVariant();

  const vacancyCards = vacanciesCardValues(isNormalViewUp, isLargeViewUp);

  const handleShowVacancies = () => {
    navigate('/vacancies');
  };

  return (
    <div className={styles.openVavanciesBlock}>
      <div className={styles.titleRow}>
        <Typography className={styles.title} variant={titleVariant}>
          Открытые вакансии
        </Typography>
        {startButton && (
          <Button onClick={handleShowVacancies} size={buttonVariant} variant="outlined">
            Смотреть все направления
          </Button>
        )}
      </div>
      <div className={styles.vacancies}>
        {vacancyCards.map((vacancy) => (
          <VacancyCard
            key={vacancy.title}
            icon={vacancy.icon}
            subtitleList={vacancy.subtitleList}
            title={vacancy.title}
          />
        ))}
      </div>
      {endButton && (
        <Button onClick={handleShowVacancies} size={buttonVariant} variant="outlined">
          Смотреть все направления
        </Button>
      )}
    </div>
  );
};
