import { FC } from 'react';

import { OpenVavanciesBlock } from './OpenVavanciesBlock';
import { SearchBlock } from './SearchBlock';
import { WorkIsBanner } from './WorkIsBanner';
import { EvolutionBlock } from './EvolutionBlock';
import styles from './styles.module.scss';
import { WhyBeeline } from './WhyBeeline';

/**
 * @description Компонент главной страницы
 */
export const Home: FC = () => {
  return (
    <div className={styles.home}>
      <SearchBlock />
      <OpenVavanciesBlock />
      <WorkIsBanner />
      <EvolutionBlock />
      <WhyBeeline />
    </div>
  );
};
