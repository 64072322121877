import { FC } from 'react';
import { Avatar, ThemeProvider, Typography } from '@beeline/design-system-react';
import { colorTextInactive } from '@beeline/design-tokens/js/tokens/globals/colors';
import cn from 'clsx';

import { ListItem } from ':components/ListItem';

import styles from './styles.module.scss';
import { useCardVariant } from './hooks';

import { WorkIsCard } from ':types';

interface WorkCardProps extends WorkIsCard {
  className?: string;
}

/**
 * @description Карточка рабочих плюшек
 */
export const WorkCard: FC<WorkCardProps> = ({ className, icon, image, title, subtitleList }) => {
  const { subtitleVariant, titleVariant } = useCardVariant();

  return (
    <div className={cn(styles.card, className)}>
      {image && (
        <div className={styles.image}>
          <img alt="BeelineTeam" src={image} />
        </div>
      )}
      <Typography className={styles.cardTitle} variant={titleVariant}>
        {title}
      </Typography>
      {icon && (
        <ThemeProvider className={styles.avatarTheme} theme="dark">
          <Avatar className={styles.avatar} iconName={icon} variant="circle" />
        </ThemeProvider>
      )}
      <div className={styles.subtitle}>
        {subtitleList.map((subtitle) => (
          <ListItem
            key={subtitle}
            className={styles.listItem}
            color={colorTextInactive}
            text={subtitle}
            variant={subtitleVariant}
          />
        ))}
      </div>
    </div>
  );
};
