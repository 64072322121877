import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import evolution from ':assets/img/evolution.png';

/**
 * @description Блок "Учись и равивайся""
 */
export const EvolutionBlock = () => {
  const navigate = useNavigate();
  const { fullButton, titleVariant, subtitleVariant } = useVariant();

  const handleRequestClick = () => {
    navigate('/vacancies');
  };

  return (
    <div className={styles.evolutionBlock}>
      <img alt="evolution" className={styles.image} src={evolution} />
      <div className={styles.content}>
        <Typography variant={titleVariant}>
          Учись и развивайся
          <br />
          на реальных задачах
        </Typography>
        <Typography className={styles.subtitle} variant={subtitleVariant}>
          Оплачиваемая стажировка в&nbsp;билайне — это шанс поработать в&nbsp;известной, технологичной компании,
          участвовать в&nbsp;разработке сервисов, которые помогают миллионам людей, завести полезные знакомства
          и&nbsp;расти как&nbsp;специалист вместе с&nbsp;нами
        </Typography>
        <div>
          <Button fullWidth={fullButton} onClick={handleRequestClick} size="medium" variant="overlay">
            Подать заявку на стажировку
          </Button>
        </div>
      </div>
    </div>
  );
};
