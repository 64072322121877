import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { SuccessVacancyApply } from ':components/SuccessVacancyApply';
import { InternVacancyForm } from ':components/InternVacancyForm';

import * as styles from './styles.module.scss';
import { MainInfo } from './MainInfo';
import { HowStart } from './HowStart';
import { useVariant } from './hooks';
import { ReturnButton } from './ReturnButton';

import { MassVacancyForm, Modal, ProfVacancyForm, ServiceError } from ':components';
import { useVacancy } from ':hooks';
import { isDefined, isInternVacancy, isMassVacancy, isProfVacancy } from ':utils';
import { WorkIsBanner } from ':pages/Home/WorkIsBanner';
import { VacancySkeleton } from ':components/Skeletons/VacancySkeleton';

/**
 * @description Страница Вакансии
 */
export const Vacancy: FC = () => {
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { id: vacancyId } = useParams<{ id: string }>();
  const { data: vacancy, isFetching } = useVacancy(vacancyId);
  const [isSuccessApply, setIsSuccessApply] = useState(false);

  const { buttonFullWidth, buttonSize, displayButton, titleVariant, hideButton } = useVariant();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const handleSuccessFormSubmit = () => {
    handleSubmit(false);
    setIsSuccessApply(true);
  };

  const handleError = () => setIsError(true);

  const handleBack = () => navigate(-1);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (isSuccessApply) {
    return <SuccessVacancyApply />;
  }

  const renderVacancyForm = () => {
    if (!vacancy) {
      return null;
    }
    const { job_type_id: jobTypeId } = vacancy;

    if (isProfVacancy(jobTypeId)) {
      return (
        <ProfVacancyForm
          onClose={handleSubmit}
          onError={handleError}
          onSuccess={handleSuccessFormSubmit}
          vacancy={vacancy}
        />
      );
    }

    // todo: доделать форму стажера
    if (isInternVacancy(jobTypeId)) {
      return (
        <InternVacancyForm
          onClose={handleSubmit}
          onSuccess={handleSuccessFormSubmit}
          vacancy={vacancy}
          vacancyName={vacancy.name}
        />
      );
    }

    if (isMassVacancy(jobTypeId)) {
      return (
        <MassVacancyForm
          onClose={handleSubmit}
          onError={handleError}
          onSuccess={handleSuccessFormSubmit}
          vacancy={vacancy}
          vacancyName={vacancy.name}
        />
      );
    }

    return null;
  };

  if (isFetching) {
    return <VacancySkeleton />;
  }

  if (!isDefined(vacancy) || isError) {
    // а может ли отработать 200 ок и не прийти вакансия?
    return <ServiceError onSecondaryClick={handleBack} variant="500" />;
  }

  return (
    <div className={styles.vacancy}>
      <MainInfo onClick={handleOpen} vacancy={vacancy} />
      <div className={styles.descriptionInfo} dangerouslySetInnerHTML={{ __html: vacancy.description }} />
      {displayButton && (
        <div className={styles.button}>
          <Button fullWidth={buttonFullWidth} onClick={handleOpen} size={buttonSize} variant="contained">
            Откликнуться
          </Button>
        </div>
      )}
      <HowStart onClick={handleOpen} />
      <WorkIsBanner
        buttonText="Откликнуться"
        buttonVariant="contained"
        cardClassName={styles.workIsCard}
        className={styles.workIsBanner}
        hideButton={hideButton}
        onClick={handleOpen}
        titleVariant={titleVariant}
      />
      <div className={styles.returnButton}>
        <ReturnButton />
        <Button onClick={handleScrollTop} size="small" variant="outlined">
          <Icon iconName={Icons.NavArrowUp} size="small" />
        </Button>
      </div>
      <Modal className={styles.modal} open={open}>
        {renderVacancyForm()}
      </Modal>
    </div>
  );
};
