import { useMutation } from '@tanstack/react-query';

import { Endpoints } from ':constants/endpoints';

import { BProfResume, QueryCallback } from ':types';
import { API } from ':api';

/**
 * @description Отправка резюме по вакансии проф.подбора
 */
export const useProfResume = ({ onError }: QueryCallback = {}) => {
  return useMutation({
    mutationKey: ['profResume'],
    mutationFn: (data: BProfResume & { [key: string]: string | File }) => {
      const body = new FormData();

      Object.keys(data).forEach((key: string) => {
        if (key === 'resume_file') {
          if (data[key]) {
            const resumeFile = data[key];
            body.append(key, resumeFile);
          }
        }

        if (key !== 'resume_file' && data[key]) {
          body.append(key, data[key] as string);
        }
      });

      return API.post({
        url: Endpoints.profResume(),
        body,
      });
    },
    onError,
  });
};
