import { TypographyVariant } from '@beeline/design-system-react';
import { ButtonSizeVariants } from '@beeline/design-system-react/types/components/Button/Button.types';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Размер кнопки */
  buttonSize: ButtonSizeVariants;
  /** Варианты карточки */
  card: {
    /** Вариант subtitle */
    subtitleVariant: TypographyVariant;
    /** Вариант title */
    titleVariant: TypographyVariant;
  };
  /** Варианты header */
  header: {
    /** Текст subtitle */
    subtitleText: string;
    /** Вариант subtitle */
    subtitleVariant: TypographyVariant;
    /** Текст title */
    titleText: string;
    /** Вариант title */
    titleVariant: TypographyVariant;
  };
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      buttonSize: 'large',
      card: {
        titleVariant: 'h4',
        subtitleVariant: 'body2',
      },
      header: {
        titleText: 'Как начать работать в билайн',
        titleVariant: 'h3',
        subtitleText: `Простой и понятный процесс найма — каждый кандидат имеет свой личный кабинет, в котором отражена
        информация о вакансии, этапах отбора и документах`,
        subtitleVariant: 'body2',
      },
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonSize: 'large',
      card: {
        titleVariant: 'h4',
        subtitleVariant: 'body2',
      },
      header: {
        titleText: 'Как начать работать в билайн',
        titleVariant: 'h4',
        subtitleText: `Простой и понятный процесс найма — каждый кандидат имеет свой личный кабинет, в котором отражена
          информация о вакансии, этапах отбора и документах`,
        subtitleVariant: 'body2',
      },
    };
  }

  return {
    buttonSize: 'medium',
    card: {
      titleVariant: 'subtitle1',
      subtitleVariant: 'body3',
    },
    header: {
      titleText: 'Как проходит отбор',
      titleVariant: 'h4',
      subtitleText: `Сроки и этапы отбора на стажировку различаются в зависимости от направления. В целом, все этапы
      занимают до месяца`,
      subtitleVariant: 'body3',
    },
  };
};
