import { Button, Icon, Skeleton } from '@beeline/design-system-react/extracted-styles';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { useMobileView } from ':hooks/useMobileView';

import styles from './styles.module.scss';

import { ReturnButton } from ':pages/Vacancy/ReturnButton';

const animated = true;
const additionalContent = [...Array(4).keys()];

/** @description Скелетон основной информации о вакансии */
export const MainInfoSkeleton = () => {
  const { isNormalViewUp } = useMobileView();

  return (
    <div className={styles.mainInfo}>
      <div className={styles.content}>
        <ReturnButton />
        <Button className={styles.shareButton} size="large">
          <Icon iconName={Icons.ShareAndroid} />
        </Button>
        <div className={styles.title}>
          <Skeleton animated={animated} className={styles.multiply} height={32} radius={12} />
          <Skeleton animated={animated} className={styles.multiply} height={32} radius={12} />
        </div>
        <Skeleton animated={animated} className={styles.multiply} height={22} radius={12} width={220} />
        <div className={styles.additionalInfo}>
          {additionalContent.map((value) => (
            <div key={value}>
              <Skeleton animated={animated} className={styles.name} height={18} radius={12} width={120} />
              <Skeleton animated={animated} height={22} radius={12} />
            </div>
          ))}
          {isNormalViewUp && <Skeleton animated={animated} radius={12} />}
        </div>
      </div>
    </div>
  );
};
