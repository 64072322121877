import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Отображение кнопки */
  displayButton: boolean;
  /** Вариант гроса */
  grossVariant: TypographyVariant;
  /** Вариант названия вакансии */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      displayButton: true,
      grossVariant: 'h4',
      titleVariant: 'h2',
    };
  }

  return {
    displayButton: false,
    grossVariant: 'h6',
    titleVariant: 'h3',
  };
};
