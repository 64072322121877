import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ChangeEvent, useEffect } from 'react';

import { Routes } from ':constants/routes';
import { useWorkFormats } from ':hooks/useWorkFormat';

import { isDefined } from ':utils';
import { useCities, useDirection, useVacancies, useGrade } from ':hooks';
import { BCity, BWorkFormat, BWorkGrade } from ':types';

/**
 * @description Получение значения параметра поиска из адресной строки
 */
export const useSearchValue = (key: string) => {
  const [searchParams] = useSearchParams();
  return searchParams.get(key);
};

/**
 * @description Получение данных на основании идентификаторов из адресной строки
 */
export const useSelectedData = <T extends { [key: string]: any }>(key: string, data: T[], prop = 'id') => {
  const selectedIds = useSearchValue(key)?.split(',') ?? [];

  if (!data || !data.length || !selectedIds.length) {
    return [];
  }

  return selectedIds
    .map((selectedId) => {
      return data.find(({ [prop]: id }) => id === selectedId);
    })
    .filter(isDefined);
};

/**
 * @description Запись выбранных идентификаторов в адресную строку
 */
export const useSetSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (key: string, selectedIds: string[]) => {
    if (!selectedIds.length) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, selectedIds.join(','));
    }
    setSearchParams(searchParams);
  };
};

export const useChangeSearchParams = () => {
  const setSearchParams = useSetSearchParams();

  const handleSelectChange = (key: string) => (data: BCity[] | BWorkGrade[] | BWorkFormat[]) => {
    const selectedIds = data.map(({ id }) => id);
    setSearchParams(key, selectedIds);
  };

  const handleCheckboxTreeChange = (roleIds: string[]) => {
    setSearchParams('roles', roleIds);
  };

  const handleCheckboxChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSearchParams(key, checked ? ['true'] : []);
  };

  return {
    handleSelectChange,
    handleCheckboxTreeChange,
    handleCheckboxChange,
  };
};

/**
 * @description Сохранение и восстановление параметров адресной строки в localStorage
 */
export const usePersistSearchParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const filters = localStorage.getItem('filters');
    if (!filters) {
      return;
    }
    navigate(
      {
        pathname: Routes.Vacancies,
        search: filters,
      },
      { replace: true },
    );
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem('filters', search);
  }, [search]);
};

/**
 * @description Получние данных для страницы вакансий
 */
export const useVacanciesData = () => {
  const { data: vacancies, isFetching: isVacanciesLoading } = useVacancies();
  const { data: cities, isFetching: isCitiesLoading } = useCities();
  const { data: directions, isFetching: isDirectionLoading } = useDirection();
  const { data: workFormats, isFetching: isFiltersLoading } = useWorkFormats();
  const { data: grades } = useGrade();

  const selectedCities = useSelectedData('cities', cities);
  const selectedGrades = useSelectedData('grades', []);
  const selectedWorkFormat = useSelectedData('format', workFormats);
  const selectedRoles = useSelectedData(
    'roles',
    directions.flatMap(({ roles }) => roles),
    'role_id',
  );
  const isDisability = Boolean(useSearchValue('disability'));
  const isInternship = Boolean(useSearchValue('internship'));

  usePersistSearchParams();

  const isLoading = isVacanciesLoading || isCitiesLoading || isDirectionLoading || isFiltersLoading;

  return {
    cities,
    directions,
    isDisability,
    isInternship,
    isLoading,
    selectedCities,
    selectedGrades,
    selectedRoles,
    selectedWorkFormat,
    vacancies,
    workFormats,
    workGrade: grades,
  };
};
