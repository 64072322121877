import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Icon, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { colorTextInactive } from '@beeline/design-tokens/js/tokens/globals/colors';
import cn from 'clsx';

import { ListItem } from ':components/ListItem';

import styles from './styles.module.scss';
import { useVariantCard } from './hooks';

interface Props {
  /** Иконка */
  icon: Icons;
  /** Список subtitle */
  subtitleList: string[][];
  /** Title */
  title: string;
}

/**
 * @description Карточка вакансии
 */
export const VacancyCard: FC<Props> = ({ icon, subtitleList, title }) => {
  const navigate = useNavigate();
  const { displayContent, titleVariant } = useVariantCard();

  const handleVacancyClick = () => {
    navigate('/vacancies');
  };

  return (
    <div className={cn(styles.vacancyCard)} onClick={handleVacancyClick} role="button" tabIndex={-1}>
      <div className={styles.cardContent}>
        <div className={styles.cardTitleRow}>
          {displayContent && <Avatar className={styles.avatar} iconName={icon} />}
          <Typography variant={titleVariant}>{title}</Typography>
        </div>
        <div className={styles.contentWithButton}>
          {displayContent && (
            <div className={styles.list}>
              {subtitleList.map((rowList) => (
                <div key={rowList[0]} className={styles.row}>
                  {rowList.map((item, itemIndex) => (
                    <ListItem
                      key={item}
                      className={styles.item}
                      color={colorTextInactive}
                      hideIcon={itemIndex === 0}
                      text={item}
                      variant="body2"
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
          <Button className={styles.button} href="/" onClick={handleVacancyClick} size="small" variant="overlay">
            К вакансиям <Icon className={styles.buttonText} iconName={Icons.ArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};
