import { FC, useState } from 'react';
import { Checkbox, Divider, ProgressButton, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';

import { useMobileView } from ':hooks';

/**
 * @description Компонент отображение футера отклика вакансии
 */

type Props = {
  /** Заблокирована ли кнопка */
  isDisabled?: boolean;
  /** Форма в статусе отправки или нет */
  isSubmitting?: boolean;
};

/**
 * @descriptionНижняя панель с кнопками и чекбоксом в форме отклика
 */
export const VacancyFormFooter: FC<Props> = ({ isSubmitting = false, isDisabled = true }) => {
  const { isNormalViewUp } = useMobileView();
  const [isChecked, setIsChecked] = useState(false);

  const checkbox = (
    <div className={styles.checkbox}>
      <Checkbox checked={isChecked} onChange={({ target }) => setIsChecked(target.checked)} />
      <Typography className={styles.text} variant="body2">
        <span>
          Я{' '}
          <a className={styles.link} href="">
            соглашаюсь на обработку персональных данных
          </a>
        </span>{' '}
        <span>
          и включение в{' '}
          <a className={styles.link} href="">
            кадровый резерв
          </a>
        </span>
      </Typography>
    </div>
  );

  if (isNormalViewUp) {
    return (
      <div className={styles.applicationFooter}>
        <Divider />

        <div className={styles.controls}>
          {checkbox}
          <ProgressButton
            disabled={!isChecked || isDisabled}
            size="large"
            state={isSubmitting ? 'loading' : 'default'}
            type="submit"
            variant="contained"
          >
            Отправить отклик
          </ProgressButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.checkboxWrapper}>{checkbox}</div>
      <div className={styles.stickyButton}>
        <ProgressButton
          className={styles.button}
          disabled={!isChecked || isDisabled}
          fullWidth
          size="large"
          state={isSubmitting ? 'loading' : 'default'}
          type="submit"
          variant="contained"
        >
          Отправить отклик
        </ProgressButton>
      </div>
    </>
  );
};
