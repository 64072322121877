import { FC } from 'react';
import { Button, Icon, Typography } from '@beeline/design-system-react/extracted-styles';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

const cards = [
  {
    icon: Icons.Mail,
    title: 'Отклик',
    subtitle: `Оставьте откликна интересную вам вакансию. Если мы увидим подходящие опыт или навыки, то рекрутер
      свяжется для назначенияинтервью`,
  },
  {
    icon: Icons.Group,
    title: 'Интервью',
    subtitle: `Чтобы попасть в команду, нужно пройти оценку экспертов, как правило, 1–2 интервью. Мы расскажем о
      вакансии, вы зададите вопросы о работе. Возможно тестовое задание`,
  },
  {
    icon: Icons.Badge,
    title: 'Финал',
    subtitle: `Подпишите договор и другие документы и начинайте работать! В билайне вы сможете заранее
      ознакомиться с документами в личном кабинете и подписать их электронной подписью`,
  },
];

interface Props {
  /** Событие клика кнопка */
  onClick?: () => void;
}

/** @description Баннер "Как начать работать в билайн" */
export const HowStart: FC<Props> = ({ onClick: handleClick }) => {
  const { buttonSize, card, header } = useVariant();

  return (
    <div className={styles.howStart}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant={header.titleVariant}>{header.titleText}</Typography>
          <Typography variant={header.subtitleVariant}>{header.subtitleText}</Typography>
        </div>
        <div className={styles.cards}>
          {cards.map(({ icon, title, subtitle }) => (
            <div key={title} className={styles.cardContent}>
              <Icon iconName={icon} size="large" />
              <Typography className={styles.title} variant={card.titleVariant}>
                {title}
              </Typography>
              <Typography className={styles.subtitle} variant={card.subtitleVariant}>
                {subtitle}
              </Typography>
            </div>
          ))}
        </div>
        <div className={styles.button}>
          <Button fullWidth onClick={handleClick} size={buttonSize} variant="contained">
            Откликнуться
          </Button>
        </div>
      </div>
    </div>
  );
};
