import { Button, Typography } from '@beeline/design-system-react';
import { FC, ReactNode } from 'react';
import clxs from 'clsx';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

interface ButtonProps {
  /** Обработчик нажатия */
  onClick?: () => void;
  /** Текст */
  text?: string;
}

export interface ImageMessageProps {
  /** Альтернативное название изображения */
  alt: string;
  /** Имя класса */
  className?: string;
  /** Описание */
  description: ReactNode;
  /** Параметры главной кнопки */
  primaryButton?: ButtonProps;
  /** Параметры второстепенной кнопки */
  secondaryButton?: ButtonProps;
  /** url изображения */
  src: string;
  /** Заголовок */
  title: string;
}

/**
 * @description Компонент отображения сообщения
 */
export const ImageMessage: FC<ImageMessageProps> = ({
  alt,
  className,
  title,
  description,
  primaryButton,
  secondaryButton,
  src,
}) => {
  const { descriptionVariant, titleVariant } = useVariant();
  const { onClick: handlePrimaryButtonClick, text: primaryButtonText } = primaryButton ?? {};
  const { onClick: handleSecondaryButtonClick, text: secondaryButtonText } = secondaryButton ?? {};

  return (
    <div className={clxs(styles.imageMessage, className)}>
      <img alt={alt} className={styles.image} src={src} />

      <div className={styles.text}>
        <Typography variant={titleVariant}>{title}</Typography>
        <Typography className={styles.description} variant={descriptionVariant}>
          {description}
        </Typography>
      </div>

      <div className={styles.controls}>
        <Button fullWidth onClick={handlePrimaryButtonClick} size="medium" variant="contained">
          {primaryButtonText}
        </Button>
        {handleSecondaryButtonClick && (
          <Button fullWidth onClick={handleSecondaryButtonClick} size="medium">
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};
