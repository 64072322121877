import { useState, ChangeEvent } from 'react';
import { Button, Search, ThemeProvider, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

const linkTags = [
  'Мерчендайзер',
  'Backend',
  'Frontend',
  'Инженер связи',
  'Продавец-консультант',
  'Оператор колл-центра',
  'Без опыта',
];

/**
 * @description Блок с поиском вакансий
 */
export const SearchBlock = () => {
  const [searchValue, setSearchValue] = useState('');
  const { buttonVariant, subtitleVariant, findButton, tagVariant, titleVariant } = useVariant();
  const navigate = useNavigate();

  const handleTagClick = () => {
    navigate('/vacancies');
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <ThemeProvider className={styles.searchBlock} theme="dark">
      <div className={styles.mainBlock}>
        <Typography variant={titleVariant}>Открой билайн для&nbsp;себя</Typography>
        <Typography className={styles.subtitle} variant={subtitleVariant}>
          Мы стремимся оказывать сервис самого высокого уровня, создавая цифровые и телеком-продукты не&nbsp;только для
          наших клиентов, но&nbsp;и&nbsp;сотрудников
        </Typography>
        <div className={styles.searchRow}>
          <Search
            fullWidth
            inputClassName={styles.search}
            onChange={handleChange}
            onClear={handleClear}
            placeholder="Введите название вакансии"
            value={searchValue}
          />
          {findButton && (
            <Button size={buttonVariant} variant="contained">
              Найти вакансию
            </Button>
          )}
        </div>
        <div className={styles.tagRow}>
          {linkTags.map((tag) => (
            <Typography key={tag} className={styles.linkTag} onClick={handleTagClick} variant={tagVariant}>
              #{tag}
            </Typography>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
};
