import { FC } from 'react';
import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import { Routes } from ':constants/routes';

import styles from './styles.module.scss';
import { Slider, Slide } from './Slider';
import { SlideContent } from './SlideContent';
import { useVariant } from './hooks';

import { sliderItems } from ':mocks';

const slides = sliderItems.map<Slide>(({ caption, image, name }) => ({
  render: ({ isSelected, disableAnimations }) => {
    return (
      <SlideContent
        caption={caption}
        disableAnimations={disableAnimations}
        embedded
        name={name}
        selected={isSelected}
        src={image}
      />
    );
  },
}));

/**
 * @description Компонент отображения слайдов
 */
export const WhyBeeline: FC = () => {
  const navigate = useNavigate();
  const { buttonSize, buttonVariant, subtitleVariant, titleVariant, isNormalViewUp } = useVariant();
  const handleRedirectToVacancies = () => navigate(Routes.Vacancies);

  return (
    <div className={styles.whyBeeline}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant={titleVariant}>Почему я выбрал билайн?</Typography>
          <Typography variant={subtitleVariant}>
            Посмотрите интервью с&nbsp;нашими сотрудниками и&nbsp;узнай, что они ценят в&nbsp;билайне
          </Typography>
        </div>

        {isNormalViewUp ? (
          <div className={styles.slides}>
            {sliderItems.map(({ caption, image, name }) => {
              return <SlideContent key={name} caption={caption} name={name} src={image} />;
            })}
          </div>
        ) : (
          <Slider slides={slides} />
        )}

        <Button className={styles.button} onClick={handleRedirectToVacancies} size={buttonSize} variant={buttonVariant}>
          Присоединиться к команде
        </Button>
      </div>
    </div>
  );
};
