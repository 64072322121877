import { Skeleton } from '@beeline/design-system-react/extracted-styles';

import styles from './styles.module.scss';

const animated = true;
const cards = [...Array(4).keys()];

/** @description Скелетон описания вакансии */
export const WorkIsSkeleton = () => {
  return (
    <div className={styles.workIs}>
      <div className={styles.bannerContent}>
        <Skeleton animated={animated} className={styles.title} height={32} radius={12} />
        <Skeleton animated={animated} className={styles.button} height={44} radius={12} width={165} />
        <div className={styles.content}>
          {cards.map((value) => (
            <div key={value} className={styles.card}>
              <Skeleton height={302} radius={12} width={253} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
