import { FC } from 'react';
import { Icon, Typography, TypographyVariant } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { colorTextActive } from '@beeline/design-tokens/js/tokens/globals/colors';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** Класс */
  className?: string;
  /** Цвет текста и точки */
  color?: string;
  /** Скрыть точку */
  hideIcon?: boolean;
  /** Текст */
  text: string;
  /** Вариант шрифта */
  variant?: TypographyVariant;
}

/** @description Текст с иконкой точки (стилизованный как <li>) */
export const ListItem: FC<Props> = ({ className, color = colorTextActive, hideIcon, text, variant = 'body2' }) => {
  return (
    <div className={cn(styles.item, className)}>
      {!hideIcon && (
        <div className={styles.icon}>
          <Icon className={styles.dotIcon} iconName={Icons.Dot} size="small" style={{ color }} />
        </div>
      )}
      <Typography style={{ color }} variant={variant}>
        {text}
      </Typography>
    </div>
  );
};
