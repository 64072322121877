import { TypographyVariant } from '@beeline/design-system-react/extracted-styles';
import { ButtonSizeVariants } from '@beeline/design-system-react/types/components/Button/Button.types';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Размер кнопки */
  buttonVariant: ButtonSizeVariants;
  /** Отображение кнопки в конце */
  endButton: boolean;
  /** Отображение кнопки в начале */
  startButton: boolean;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      buttonVariant: 'large',
      endButton: false,
      startButton: true,
      titleVariant: 'h2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonVariant: 'large',
      endButton: false,
      startButton: true,
      titleVariant: 'h3',
    };
  }

  return {
    buttonVariant: 'medium',
    endButton: true,
    startButton: false,
    titleVariant: 'h4',
  };
};

interface VariantCardType {
  /** Отображение списка в карточке */
  displayContent: boolean;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов карточки в зависимости от размера экрана */
export const useVariantCard = (): VariantCardType => {
  const { isMobilePlusViewUp, isNormalViewUp } = useMobileView();

  if (isNormalViewUp) {
    return {
      displayContent: true,
      titleVariant: 'h4',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      displayContent: true,
      titleVariant: 'h4',
    };
  }

  return {
    displayContent: false,
    titleVariant: 'subtitle1',
  };
};
