import { useNavigate } from 'react-router-dom';
import { Button, Typography, TypographyVariant } from '@beeline/design-system-react';
import { FC } from 'react';
import { ButtonColorVariants } from '@beeline/design-system-react/types/components/Button/Button.types';
import cn from 'clsx';

import { works } from ':mocks/works';

import styles from './styles.module.scss';
import { WorkCard } from './workCard';
import { useVariant } from './hooks';

import BeelineTeam from ':assets/img/BeelineTeam.png';

interface Props {
  /** Текст кнопки */
  buttonText?: string;
  /** Вариант кнопки */
  buttonVariant?: ButtonColorVariants;
  /** Стиль для карточки */
  cardClassName?: string;
  /** Стиль */
  className?: string;
  /** Скрыть кнопку */
  hideButton?: boolean;
  /** Событие клика кнопка */
  onClick?: () => void;
  /** Вариант заголовка */
  titleVariant?: TypographyVariant;
}

/**
 * @description Блок "Работа в билайн - это"
 */
export const WorkIsBanner: FC<Props> = ({
  buttonText = 'Стать частью команды',
  buttonVariant = 'overlay',
  cardClassName,
  className,
  hideButton,
  onClick,
  titleVariant: titleVar,
}) => {
  const navigate = useNavigate();
  const { buttonSize, titleVariant } = useVariant(titleVar);

  const handleTeamClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    navigate('/vacancies');
  };

  return (
    <div className={cn(styles.workIsBanner, className)}>
      <div className={styles.bannerContent}>
        <Typography className={styles.title} variant={titleVariant}>
          Работа в билайне &mdash; это
        </Typography>
        <div className={styles.content}>
          {works.map((work, index) => (
            <WorkCard
              key={work.title}
              className={cardClassName}
              icon={work.icon}
              image={index === 0 && BeelineTeam}
              subtitleList={work.subtitleList}
              title={work.title}
            />
          ))}
        </div>
        {!hideButton && (
          <Button className={styles.button} onClick={handleTeamClick} size={buttonSize} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
