import { z } from 'zod';

export const schema = z
  .object({
    last_name: z.string().min(1, { message: 'Поле является обязательным' }),
    first_name: z.string().min(1, { message: 'Поле является обязательным' }),
    phone: z
      .string()
      .regex(/^\+7\d{10}$/, {
        message: 'Неверный формат телефонного номера',
      })
      .min(8, {
        message: 'Формат должен быть +7 или неправильный номер телефона',
      }),
    resume_url: z.string().optional(),
    resume_file: z.instanceof(File).optional(),
    cover_letter: z.string().optional(),
    email: z.string().optional(),
    telegram_url: z.string().optional(),
    middle_name: z.string().optional(),
  })
  .refine(
    (data) => {
      return data.resume_url || data.resume_file;
    },
    {
      message: 'Заполните хотя бы одно из полей: Ссылка на резюме или Загрузка резюме',
      path: ['resume_url'],
    },
  );
