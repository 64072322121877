import { Skeleton } from '@beeline/design-system-react';

import styles from './styles.module.scss';

const animated = true;
const cards = [...Array(4).keys()];
const filterList = [...Array(16).keys()];

/** @description Скелетон для списка вакансий */
export const VacanciesSkeleton = () => {
  return (
    <div className={styles.vacancies}>
      <div className={styles.header}>
        <Skeleton animated={animated} height={24} radius={12} width={196} />
        <div className={styles.description}>
          <Skeleton animated={animated} height={48} radius={12} />
          <Skeleton animated={animated} className={styles.icon} height={48} radius={12} width={48} />
        </div>
      </div>
      <div className={styles.filter}>
        <Skeleton animated={animated} height={48} radius={12} />
        <Skeleton animated={animated} height={48} radius={12} />
        <Skeleton animated={animated} height={48} radius={12} />
        <Skeleton animated={animated} height={48} radius={12} />
        <div className={styles.filterRow}>
          <Skeleton animated={animated} height={24} radius={12} />
          <Skeleton animated={animated} height={24} variant="circle" />
        </div>
        <div className={styles.filterList}>
          {filterList.map((value) => (
            <Skeleton key={value} animated={animated} height={24} radius={12} />
          ))}
        </div>
        <Skeleton animated={animated} className={styles.filterButton} height={48} radius={12} />
        <Skeleton animated={animated} height={48} radius={12} />
      </div>
      <div className={styles.cards}>
        {cards.map((value) => (
          <div key={value} className={styles.card}>
            <Skeleton animated={animated} height={32} radius={12} width={182} />
            <Skeleton animated={animated} height={32} radius={12} />
            <Skeleton animated={animated} height={20} radius={12} width={120} />
            <div className={styles.cardDescription}>
              <Skeleton animated={animated} height={22} radius={12} />
              <Skeleton animated={animated} height={22} radius={12} />
              <Skeleton animated={animated} className={styles.cardUnderRow} height={22} radius={12} />
              <Skeleton animated={animated} height={40} radius={12} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
